import { PageOptions } from '@graphcommerce/framer-next-pages'
import { HygraphPagesQuery, hygraphPageContent } from '@graphcommerce/graphcms-ui'
import { flushMeasurePerf } from '@graphcommerce/graphql'
import {
  CategoryChildren,
  CategoryDescription,
  CategoryMeta,
  getCategoryStaticPaths,
} from '@graphcommerce/magento-category'
import {
  extractUrlQuery,
  FilterTypes,
  getFilterTypes,
  parseParams,
  ProductFiltersDocument,
  ProductFiltersQuery,
  ProductListDocument,
  ProductListParams,
  ProductListQuery,
} from '@graphcommerce/magento-product'
import { StoreConfigDocument, redirectOrNotFound } from '@graphcommerce/magento-store'
import {
  LayoutTitle,
  GetStaticProps,
  Button,
  MetaRobots,
  useStorefrontConfig,
  breakpointVal,
} from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Container, Typography, Box, ButtonBase } from '@mui/material'
import { GetStaticPaths } from 'next'
import { useEffect } from 'react'
import {
  LayoutNavigation,
  LayoutNavigationProps,
  LayoutHeaderVaessen,
  RowProduct,
  RowRenderer,
  CategoryFilterLayout,
} from '../components'
import { CategoryBanner } from '../components/Category/CategoryBanner'
import { CategoryBreadcrumbs } from '../components/Category/CategoryBreadcrumb'
import { CategoryThumbnail, fiveColumnLayoutPages } from '../components/Category/CategoryThumbnail'
import { CategoryTitle } from '../components/Category/CategoryTitle'
import { RowProductList } from '../components/GraphCMS/RowProductList/RowProductList'
import { RowProductListFragment } from '../components/GraphCMS/RowProductList/RowProductList.gql'
import { CategoryHreflangs } from '../components/Hreflangs/CategoryHreflangs'
import { CmsPageHreflangs } from '../components/Hreflangs/CmsPageHreflangs'
import { LayoutDocument } from '../components/Layout/Layout.gql'
import { useCustomerProductPrices } from '../components/Product/useCustomerProductPrices'
import { TrustPilotCarousel } from '../components/TrustPilot/TrustPilotCarousel'
import { CategoryPageDocument, CategoryPageQuery } from '../graphql/CategoryPage.gql'
import { GetProductsBySkuQuery } from '../graphql/GetProductsBySku.gql'
import { getProductsBySku } from '../lib/get-products-by-sku/get-products-by-sku'
import { graphqlSsrClient, graphqlSharedClient } from '../lib/graphql/graphqlSsrClient'

export type CategoryProps = CategoryPageQuery &
  HygraphPagesQuery &
  ProductListQuery &
  ProductFiltersQuery & { filterTypes?: FilterTypes; params?: ProductListParams } & {
    rowProductListData: GetProductsBySkuQuery[]
  }
export type CategoryRoute = { url: string[] }

type GetPageStaticPaths = GetStaticPaths<CategoryRoute>
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, CategoryProps, CategoryRoute>

function CategoryPage(props: CategoryProps) {
  const { categories, products, filters, params, filterTypes, pages, rowProductListData } = props
  const productsWithCustomerSpecificPrices = useCustomerProductPrices(products)
  const productList = productsWithCustomerSpecificPrices?.items

  const page = pages?.[0]
  const category = categories?.items?.[0]
  const isLanding = category?.display_mode === 'PAGE'
  const isCategory =
    params &&
    category &&
    products?.items &&
    filterTypes &&
    (category?.display_mode === 'PRODUCTS_AND_PAGE' || category?.display_mode === 'PRODUCTS')

  const cmsPageHrefLangs =
    !category?.hreflang && page?.localizations?.length ? page?.localizations : false

  const title = page?.title ?? ''
  const storeB2B = useStorefrontConfig().magentoStoreCode.includes('b2b')

  useEffect(() => {
    if (isLanding) {
      globalThis.gtag?.('event', 'view_category', {
        title: page?.title,
        url: page?.url,
      })
    }
  }, [isLanding, page])

  let rowProductListIndex = 0

  return (
    <>
      {category?.hreflang && <CategoryHreflangs hreflangs={category.hreflang} />}
      {cmsPageHrefLangs && page?.locale && page.url && (
        <CmsPageHreflangs hreflangs={{ ...page, localizations: cmsPageHrefLangs }} />
      )}
      <CategoryMeta
        params={params}
        title={page?.metaTitle}
        metaDescription={page?.metaDescription}
        metaRobots={
          storeB2B
            ? ['noindex', 'nofollow']
            : (page?.metaRobots.toLowerCase().split('_') as MetaRobots[]) ?? undefined
        }
        canonical={page?.url ? `/${page.url}` : undefined}
        {...category}
      />
      <LayoutHeaderVaessen
        floatingMd
        sx={[
          Boolean(isCategory) &&
            ((theme) => ({
              [theme.breakpoints.up('md')]: {
                '& .LayoutHeaderContent-left': {
                  display: 'none',
                },
              },
            })),
        ]}
      >
        <LayoutTitle size='small' component='span'>
          {category?.name ?? title}
        </LayoutTitle>
      </LayoutHeaderVaessen>

      {isCategory && (
        <Container maxWidth={false} sx={(theme) => ({ marginBottom: theme.spacings.xxs })}>
          <CategoryBreadcrumbs
            category={category}
            breadcrumbsAmountMobile={2}
            breadcrumbsAmountDesktop={2}
            itemSx={(theme) => ({
              fontWeight: 400,
              ...breakpointVal('fontSize', 12, 13, theme.breakpoints.values),
            })}
            sx={(theme) => ({
              '& .MuiBreadcrumbs-separator': {
                ...breakpointVal('fontSize', 12, 13, theme.breakpoints.values),
              },
            })}
          />
        </Container>
      )}

      {isLanding && (
        <>
          <CategoryTitle
            category={category}
            sx={(theme) => ({ marginTop: 0, marginBottom: theme.spacings.xs })}
          />
          <CategoryDescription
            description={category.description}
            sx={(theme) => ({
              margin: `${theme.spacings.xs} auto ${theme.spacings.sm}`,
              [theme.breakpoints.up('xl')]: {
                width: '50%',
                maxWidth: 1024,
              },
            })}
          />
          <Container
            maxWidth='lg'
            sx={(theme) => ({
              marginTop: theme.spacings.lg,
              marginBottom: theme.spacings.lg,
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'grid',
                gridTemplateColumns: {
                  xs: `repeat(2, 1fr)`,
                  sm: `repeat(3, 1fr)`,
                  md: fiveColumnLayoutPages.includes(category.uid)
                    ? `repeat(5, 1fr)`
                    : `repeat(4, 1fr)`,
                },
                rowGap: theme.spacings.lg,
                columnGap: theme.spacings.md,
              })}
            >
              {category?.children?.map((item) => {
                if (!item?.url_path || !item.uid || !item.name) return null
                return (
                  <Box
                    key={item.uid}
                    sx={(theme) => ({
                      display: 'grid',
                      gap: theme.spacings.xs,
                      alignContent: 'space-between',
                    })}
                  >
                    <ButtonBase key={item.name} href={item.url_path}>
                      <CategoryThumbnail
                        name={item.thumbnail}
                        thumbnail={item?.thumbnail}
                        categoryUid={category.uid}
                      />
                    </ButtonBase>

                    <Typography
                      variant='h5'
                      component='h2'
                      color='textPrimary'
                      sx={{ justifySelf: 'center', wordBreak: 'break-word', textAlign: 'center' }}
                    >
                      {item.name}
                    </Typography>

                    <Button
                      key={item.url_path}
                      href={item.url_path}
                      variant='outlined'
                      size='medium'
                      color='secondary'
                      sx={{ justifySelf: 'center' }}
                    >
                      <Trans id='View' />
                    </Button>
                  </Box>
                )
              })}
            </Box>
          </Container>

          <Container
            maxWidth='lg'
            sx={(theme) => ({
              marginTop: theme.spacings.lg,
              marginBottom: theme.spacings.lg,
            })}
          >
            <TrustPilotCarousel />
          </Container>

          {category.seo_long_description && (
            <Container
              dangerouslySetInnerHTML={{
                __html: category.seo_long_description,
              }}
              sx={(theme) => ({ marginTop: theme.spacings.lg })}
            />
          )}
        </>
      )}
      {isCategory && (
        <>
          <CategoryBanner image={category.image} />

          <CategoryTitle category={category} />

          <CategoryDescription
            description={category.description}
            sx={(theme) => ({
              typography: 'body1',
              [theme.breakpoints.up('xl')]: {
                width: '50%',
                maxWidth: 1024,
              },
            })}
          />
          <CategoryChildren
            params={params}
            sx={(theme) => ({
              '& .Scroller-root': {
                gap: theme.spacings.xs,
              },
            })}
          >
            {category.children}
          </CategoryChildren>
          <CategoryFilterLayout
            params={params}
            filters={filters}
            products={productsWithCustomerSpecificPrices}
            filterTypes={filterTypes}
            title={category.name ?? ''}
            id={category.uid}
          />

          {category.seo_long_description && (
            <Container
              maxWidth='lg'
              dangerouslySetInnerHTML={{ __html: category.seo_long_description }}
            />
          )}
        </>
      )}
      {!isLanding && !isCategory && page && (
        <>
          <LayoutTitle
            variant='h1'
            gutterTop={false}
            sx={(theme) => ({
              '&.gutterBottom': {
                marginTop: theme.spacings.xl,
                marginBottom: theme.spacings.lg,
              },
            })}
          >
            {title}
          </LayoutTitle>
          <RowRenderer
            content={page.content}
            renderer={{
              RowProduct: (rowProps) => (
                <RowProduct {...rowProps} {...productList?.[0]} items={productList?.slice(0, 8)} />
              ),
              RowProductList: (rowProps) => {
                rowProductListIndex++
                return (
                  <RowProductList
                    {...rowProps}
                    productsBySku={rowProductListData[rowProductListIndex - 1].productsBySku}
                  />
                )
              },
            }}
          />
        </>
      )}
    </>
  )
}

const pageOptions: PageOptions<LayoutNavigationProps> = {
  Layout: LayoutNavigation,
}
CategoryPage.pageOptions = pageOptions

export default CategoryPage

export const getStaticPaths: GetPageStaticPaths = async ({ locales = [] }) => {
  return { paths: [], fallback: 'blocking' } // no SSG for now!
  // Disable getStaticPaths while in development mode
  if (process.env.NODE_ENV === 'development') return { paths: [], fallback: 'blocking' }

  const path = (loc: string) => getCategoryStaticPaths(graphqlSsrClient(loc), loc)
  const paths = (await Promise.all(locales.map(path))).flat(1)
  return { paths: paths.slice(0, 100), fallback: 'blocking' }
}

export const getStaticProps: GetPageStaticProps = async ({ params, locale }) => {
  const [url, query] = extractUrlQuery(params)
  if (!url || !query) return { notFound: true }

  const client = graphqlSharedClient(locale)
  const conf = client.query({ query: StoreConfigDocument })
  const filterTypes = getFilterTypes(client)

  const staticClient = graphqlSsrClient(locale)

  const categoryPage = staticClient.query({ query: CategoryPageDocument, variables: { url } })
  const layout = staticClient.query({ query: LayoutDocument, fetchPolicy: 'cache-first' })

  const productListParams = parseParams(url, query, await filterTypes)
  const filteredCategoryUid = productListParams && productListParams.filters.category_uid?.in?.[0]

  const category = categoryPage.then((res) => res.data.categories?.items?.[0])
  let categoryUid = filteredCategoryUid
  if (!categoryUid) {
    categoryUid = (await category)?.uid ?? ''
    if (productListParams) productListParams.filters.category_uid = { in: [categoryUid] }
  }

  const pages = hygraphPageContent(staticClient, url, category)
  const hasCategory = Boolean(productListParams && categoryUid)

  const filters = hasCategory
    ? staticClient.query({
        query: ProductFiltersDocument,
        variables: { filters: { category_uid: { eq: categoryUid } } },
      })
    : undefined
  const products = hasCategory
    ? staticClient.query({
        query: ProductListDocument,
        variables: {
          pageSize: (await conf).data.storeConfig?.grid_per_page ?? 24,
          ...productListParams,
          filters: { ...productListParams?.filters, category_uid: { eq: categoryUid } },
        },
      })
    : undefined

  const hasPage = filteredCategoryUid ? false : (await pages).data.pages.length > 0
  if (!hasCategory && !hasPage) return redirectOrNotFound(staticClient, conf, params, locale)

  if ((await products)?.errors) return { notFound: true }

  const { category_name, category_url_path } =
    (await categoryPage).data.categories?.items?.[0]?.breadcrumbs?.[0] ?? {}

  const singlePage = (await pages).data.pages?.[0]

  const rowProductListData: GetProductsBySkuQuery[] = []
  if (singlePage) {
    const rowProductListInstancesSkus = singlePage.content
      .filter((instance) => instance.__typename === 'RowProductList')
      .map((instance) => (instance as RowProductListFragment).skUs)

    for (const skus of rowProductListInstancesSkus) {
      // eslint-disable-next-line no-await-in-loop
      const productData = await getProductsBySku(staticClient, skus.replaceAll(' ', '').split(','))
      rowProductListData.push(productData)
    }
  }

  const up =
    category_url_path && category_name
      ? { href: `/${category_url_path}`, title: category_name }
      : { href: `/`, title: 'Home' }

  const result = {
    props: {
      ...(await categoryPage).data,
      ...(await products)?.data,
      ...(await pages).data,
      ...(await filters)?.data,
      ...(await layout).data,
      filterTypes: await filterTypes,
      rowProductListData,
      params: productListParams,
      apolloState: await conf.then(() => client.cache.extract()),
      up,
    },
    revalidate: 60 * 20,
  }
  flushMeasurePerf()
  return result
}
